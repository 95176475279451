import { createContext, useContext, useState, useEffect } from 'react'

export const RoundsWebsocketContext = createContext<null | WebSocket>(null);
export const RoundsWebsocketProvider = RoundsWebsocketContext.Provider;
export const useRoundsWebsocket = () => useContext(RoundsWebsocketContext);
export const useInitRoundsWebsocket = () => {
  const [socket, setSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    const ws = new WebSocket('ws://localhost:8080/ws/rounds');
    ws.onopen = () => {
      console.log('Connected to game round ws server');
    };

    ws.onmessage = (event) => {
      console.log('message from /ws/rounds:', event.data);
    };

    ws.onerror = (error) => {
      console.error('/ws/rounds:', error);
    };

    ws.onclose = () => {
      console.log('Disconnected from /ws/rounds ws server');
    };

    setSocket(ws);
    return () => { ws.close(); };
  }, []);

  return socket;
};
