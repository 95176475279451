export const palette = {
  gray: {
    900: "#121619",
    850: "#1A1E22",
    800: "#21252B",
    750: "#262C31",
    700: "#2A3136",
    600: "#4D5358",
    500: "#697077",
    400: "#8A8C95",
    300: "#ADADB3",
    200: "#C7C7CC",
    150: "#DEDEE3",
    100: "#E5E5EA",
    75: "#EBEBF1",
    50: "#F3F3F8",
    25: "#F4F5FB",
  },
  acid: {
    900: "#304200",
    800: "#3a5000",
    700: "#486400",
    600: "#5d7a00",
    500: "#749900",
    400: "#93bc00",
    300: "#b2e400",
    200: "#ccfd07 ",
    100: "#e7ff87",
    50: "#f5ffce",
    25: "#faffe7",
  },
  orange: {
    900: "#211001",
    800: "#3C1E02",
    700: "#5D2D01",
    600: "#814003",
    500: "#B15601",
    400: "#DF6D04",
    300: "#FF8A14",
    200: "#FFB675",
    100: "#FFDBB3",
    50: "#FFF4E5",
  },
};

export const semanticTokens = {
  colors: {
    "ui-01-inverse": {
      _dark: "gray.50",
      _light: "gray.800",
    },
    "border-01": {
      _dark: "gray.700",
      _light: "gray.100",
    },
    "text-03": {
      _dark: "gray.400",
      _light: "gray.500",
    },
    "text-inverse": {
      _dark: "gray.900",
      _light: "gray.50",
    },
  },
};
