export const semanticTokens = {
  colors: {
    "tomars-up": {
      _dark: "green.200",
      _light: "green.300",
    },
    "tomars-down": {
      _dark: "red.400",
      _light: "red.400",
    },
    "tomars-text-on-up": {
      _dark: "gray.800",
      _light: "gray.50",
    },
    "tomars-text-on-down": {
      _dark: "gray.50",
      _light: "gray.50",
    },
  }
};
