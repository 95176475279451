import type { ReactNode } from 'react'
import { WagmiProvider } from 'wagmi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import rainbowStylesUrl from '@rainbow-me/rainbowkit/styles.css?url';
import { RainbowKitProvider } from '@rainbow-me/rainbowkit';
import { LinksFunction } from '@remix-run/node';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';

import { wagmiConfig } from '@/config/wagmi';
import { semanticTokens as semanticTokensDefault, palette } from '@/chakra-theme';
import { semanticTokens as semanticTokenToMars } from '#app/theme';
import { useInitRoundsWebsocket, RoundsWebsocketProvider } from '#app/websocket-providers'

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: rainbowStylesUrl },
]

const queryClient = new QueryClient();

const theme = extendTheme({
  colors: palette,
  semanticTokens: {
    colors: {
      ...semanticTokenToMars.colors,
      ...semanticTokensDefault.colors,
    }
  }
});

/**
 * Import external & internal providers for the application context collectively here.
 */
export default function Providers({ children }: { children: ReactNode }) {
  const roundSocket = useInitRoundsWebsocket()

  return (
    <RoundsWebsocketProvider value={roundSocket}>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <ChakraProvider theme={theme}>
              {children}
            </ChakraProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </RoundsWebsocketProvider>
  );
};
