import {
  json,
  type LoaderFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { HoneypotProvider } from 'remix-utils/honeypot/react'

import rainbowKitAdjustmentUrl from '#app/theme/rainbowkit-adjustment.css?url';
import highchartsThemeUrl from '#app/components/highcharts/theme.css?url';

import { env } from '@/config';
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { href as iconsHref } from './components/ui/icon.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getEnv } from './utils/env.server.ts'
import { honeypot } from './utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { type Theme, getTheme } from './utils/theme.server.ts'
import { makeTimings } from './utils/timing.server.ts'
import Providers, { links as providerLinks } from './providers';

export const links: LinksFunction = () => {
  return [
    // Preload svg sprite as a resource to avoid render blocking
    { rel: 'preload', href: iconsHref, as: 'image' },
    { rel: 'mask-icon', href: '/favicons/mask-icon.svg' },
    {
      rel: 'alternate icon',
      type: 'image/png',
      href: '/favicons/favicon-32x32.png',
    },
    { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      crossOrigin: 'use-credentials',
    } as const, // necessary to make typescript happy
    { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
    { rel: 'stylesheet', href: tailwindStyleSheetUrl },
    { rel: 'stylesheet', href: highchartsThemeUrl },
    { rel: 'stylesheet', href: rainbowKitAdjustmentUrl },
    // Google Fonts
    { rel: 'preconnect', href: 'https://fonts.googleapis.com' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com', crossOrigin: 'anonymous' },
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap' },
    ...providerLinks(),
  ].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [
    { title: data ? 'To Mars' : 'Error | To Mars' },
    { name: 'description', content: `Your own captain's log` },
  ]
}

export async function loader({ request, context }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader')
  const honeyProps = honeypot.getInputProps()

  return json(
    {
      requestInfo: {
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request),
        },
      },
      nonce: context.cspNonce as string,
      ENV: getEnv(),
      honeyProps,
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
      ),
    },
  )
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
  }
  return headers
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {},
  allowIndexing = true,
}: {
  children: React.ReactNode
  nonce: string
  theme?: Theme
  env?: Record<string, string>
  allowIndexing?: boolean
}) {
  return (
    <html lang="en" className={`${theme} h-full overflow-x-hidden`}>
      <head>
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {allowIndexing ? null : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <Links />
      </head>
      <body className="bg-background text-foreground">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
      </body>
    </html>
  )
}

/**
 * NOTE: To avoid server side renders different html than client side is because
 * of nonce. The server side uses `helmet` to generate cspNonce. This cspNonce is
 * used to render html string on the server side via following code:
 *
 * ```
 * <NonceProvider nonce={nonce}>
 *  ...
 * </NonceProvider>
 * ```
 * However, the context value in `NonceProvider` will not be available on the client side.
 * We need to retrieve cspNonce from request context for client side rendering to retrieve
 * correct nonce value.
 */
export function App() {
  const data = useLoaderData<typeof loader>()
  const nonce = typeof window === 'undefined' || env.NODE_ENV === 'development'
    ? useNonce()
    : data.nonce;
  const theme: Theme = 'light'
  const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false'

  return (
    <Document
      nonce={nonce}
      theme={theme}
      allowIndexing={allowIndexing}
      env={data.ENV}
    >
      <Providers>
        <Outlet />
      </Providers>
    </Document>
  )
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>()
  return (
    <HoneypotProvider {...data.honeyProps}>
      <App />
    </HoneypotProvider>
  )
}

export default withSentry(AppWithProviders)


export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce()

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  )
}
